const URL_BASE = 'https://oa2021.weirane.workers.dev';
// const URL_BASE = 'http://localhost:8787';

export type Post = {
  title: string;
  username: string;
  content: string;
};

export function hashPost(post: Post): number {
  const concat = post.title + post.username + post.content;
  let hash = 0;
  for (const chr of concat) {
    hash = (hash << 5) - hash + chr.charCodeAt(0);
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export async function getPosts(): Promise<Post[]> {
  const res = await fetch(URL_BASE + '/posts');
  const posts = await res.json();
  return posts;
}

export async function postPost({
  title,
  username,
  content,
}: Post): Promise<Response> {
  const res = await fetch(URL_BASE + '/posts', {
    method: 'POST',
    body: JSON.stringify({ title, username, content }),
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });
  return res;
}

const exports = { hashPost, getPosts, postPost };
export default exports;
