import React from 'react';
import './App.css';
import util, { Post } from './util';

class CreateNewPost extends React.Component<{}, Post> {
  constructor(props: any) {
    super(props);
    this.state = { title: '', username: '', content: '' };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    switch (e.currentTarget.name) {
      case 'title':
        this.setState({ title: e.currentTarget.value });
        break;
      case 'username':
        this.setState({ username: e.currentTarget.value });
        break;
      case 'content':
        this.setState({ content: e.currentTarget.value });
        break;
    }
  }

  async handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    await util.postPost(this.state);
    window.location.reload();
  }

  render() {
    return (
      <div className="create-post">
        <div className="create-post-title">Add a new post:</div>
        <form onSubmit={this.handleSubmit}>
          <div className="input-label">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              name="title"
              required={true}
              value={this.state.title}
              onChange={this.handleChange}
            ></input>
          </div>
          <div className="input-label">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              name="username"
              required={true}
              value={this.state.username}
              onChange={this.handleChange}
            ></input>
          </div>
          <label htmlFor="content">Content</label>
          <br />
          <textarea
            name="content"
            required={true}
            rows={5}
            className="content-input"
            value={this.state.content}
            onChange={this.handleChange}
          ></textarea>
          <p>
            <button type="submit" name="submit">
              Submit
            </button>
          </p>
        </form>
      </div>
    );
  }
}

type PostsState = {
  posts: Post[];
};

class Posts extends React.Component<{}, PostsState> {
  constructor(props: any) {
    super(props);
    this.state = { posts: [] };
  }

  async componentDidMount() {
    await this.updatePosts();
  }

  async updatePosts() {
    const posts = await util.getPosts();
    this.setState({ posts: posts });
  }

  render() {
    return (
      <div className="posts">
        <h2>Posts</h2>
        <div>
          {this.state.posts
            .map((p) => (
              <div key={util.hashPost(p)}>
                <h3 className="post-title">{p.title}</h3>
                <div className="post-author">
                  by @{p.username || 'anonymous'}
                </div>
                <div className="post-content">{p.content}</div>
              </div>
            ))
            .reverse()}
        </div>
      </div>
    );
  }
}

function App() {
  return (
    <div className="App">
      <CreateNewPost />
      <Posts />
    </div>
  );
}

export default App;
